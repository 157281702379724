.customer-page {
  & { height: 100%; }

  .template-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
    h1 { font-size: 24px; color: #3D3935; font-weight:600; letter-spacing: 0px; margin: 20px 0px; }
    .header-actions {
      & { display: flex; align-items: center; justify-content: center; gap: 10px; }
    }
  }

  .btn-container { 
    & { display: flex; gap: 15px;}
    .btn-primary { padding: 4px 10px; font-size: 17px; font-weight: 400; }
  }

  .template-section {
    & { height: calc(100% - 60px); overflow-y: scroll; }
    .MuiDataGrid-root { background-color: transparent; color: #9B9B9B; }
    .MuiDataGrid-columnHeaderTitle { color: #9B9B9B; font-size: 14px; text-transform: capitalize !important; }
    .MuiDataGrid-root {
      & { border: none !important; }
      .MuiDataGrid-iconSeparator { visibility: hidden; }
      .MuiDataGrid-row { 
        & { box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 3px; margin: 5px 60px 5px 8px; border-radius: 10px; background-color: #fff; font-size: 14px !important; margin-right: 30px; color: #3D3935; width: calc(100% - 60px); }
        .MuiDataGrid-cell { 
          & { border-bottom: 0px !important; }
        }
      }
    }
    & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }
    .view-more-button { background-color: #AA7B59; padding: 7px 20px; font-size: 14px; color: #ffffff; font-weight: 700; width: fit-content; margin-left: auto; border-radius: 5px; cursor: pointer;}
  }
}