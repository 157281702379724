.upload-image-modal-container {
  & { height: 80vh; width: 60vw; display: flex; flex-direction: column; align-items: center; justify-content: space-between; }

  .title { 
    & { height: 50px; width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 0 15px; }
    h2 { margin: 0; color: #AA7B59; }
    .MuiSvgIcon-root { color: #AA7B59; cursor: pointer;}
  }

  .crop {
    & {  height: calc(100% - 130px); padding: 10px 0; position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    .d-n-d { 
      & { height: 80%; width: 90%; border: 2px dashed #929292; border-radius: 10px; display: flex; align-items: center; justify-content: center;}
    }
  }

  .crop-btns {
    & { position: relative; height: 80px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .tips {
      & { position: absolute; bottom: -10px; left: 0px; display: flex; align-items: center; justify-content: flex-start; }
      &>span {
        & { color: #AA7B59; font-size: 14px; }
      }
    }
  }
}


.select-item-modal {
  & { max-height: 600px; width: 575px; min-width: 550px; max-width: 550px; display: flex;flex-direction: column; gap: 1rem; }
  section {
    & { display: flex; flex-direction: column; height: 90%; gap: 0.5rem; }

    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; }
      .close-btn { color: #1a66ff; background: #fff; border: 2px solid #1a66ff; }
    }

    .search-item-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 16px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .items-menu {
      & { width: 100%;  max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 40px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
        }
        .add-new-item {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .item-text { color: #1a66ff; font-weight: 600; }
        }
      }
    }
    .item-list { 
      & { display: flex; flex-wrap: wrap; gap: 1rem;}
      .item { 
        & { background-color: #e7e7e7; width: fit-content; border-radius: 4px; display: flex; align-items: center; justify-content: center; padding: 0.5rem;gap: 0.5rem; }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-wrap: wrap; }
    }
  }
}

.enter-time-modal{
  &{ display: flex; flex-direction: column; gap: 20px; width: 30vw; }
  .enter-time-heading{
    &{ display: flex; justify-content: space-between; }
    svg{ cursor: pointer; }
  }
  .enter-time-input-container{
    &{ display: flex; flex-direction: column; gap: 5px; }
    input{
      &{ width: 100%; } 
    }
    input::placeholder{
      &{ font-size: 14px; }
    }
  }
  .save-changes-btn{
    &{ display: flex; justify-content: space-between; gap: 20px; }
    button{
      &{ width: 100%; }
    }
  }
}

.enter-ingredient-modal{
  &{ display: flex; flex-direction: column; gap: 20px; width: 30vw; }
  .enter-ingredient-heading{
    &{ display: flex; justify-content: space-between; }
    svg{ cursor: pointer; }
  }
  .enter-ingredient-input-container{
    &{ display: flex; flex-direction: column; gap: 5px; }
    input{
      &{ width: 100%; border-radius: 5px; border: 2px solid #e4e4e4; padding: 5px 10px; } 
    }
    input::placeholder{
      &{ font-size: 14px; }
    }
    ul { position: absolute; top: 100%; left: 0; right: 0; background-color: white;  border: 1px solid #ccc; border-radius: 4px; list-style: none; margin: 0; padding: 0; max-height: 150px; overflow-y: auto; z-index: 1; }
    li { padding: 8px; cursor: pointer}
    select{ border-radius: 5px; border: 2px solid #e4e4e4; padding: 5px 10px; }
  }
  .link-hh-product{
    &{ display: flex; flex-direction: column; gap: 5px; }
    .select-tag{
      &{ width: 100%; font-size: 14px; padding: 5px 0; outline: none; } 
    }
    .option-container{
      &{ display: flex; justify-content: space-between; align-items: center; }
      .select-icon{
        svg{ color: #c7c7c7; }
      }
    }
  }
  .save-changes-btn{
    &{ display: flex; justify-content: space-between; gap: 20px; }
    button{
      &{ width: 100%; }
    }
  }
  .checkbox-container {
    & {  }
    .MuiRadio-root.Mui-checked {
      & { color: #AA7B59; }
    }
  }
}

.edit-tags-container {
  & { width: auto; min-width: 550px; padding: 20px; max-height: 80vh; overflow-y: scroll; }
  section {
    & { display: flex; flex-direction: column; height: 90%; }

    .address-card-container{ display: flex; flex-direction: column; gap: 10px;
      .card{ backgroundcolor: #FCFAF9; padding: 10px 0px; border-radius: 10px;  width: 100%;
        .address-card{
        width: 100%; display: flex; gap: 20px; align-items: center;
          p{ color: #3D3935; font-size: 16px; } 
          }
        .edit-icon{
          display: flex; width: 100%;align-items: center;justify-content: flex-end; gap: 5px; margin-bottom: 10px; margin-right: 10px;  }
      }
      .add-address { background-color: #EEEEEE; border-radius: 10px; display: flex; font-size: 18px; align-items: center; justify-content: center; color: #9B9B9B; height: 43px;padding: 10px; margin-bottom: 10px; cursor: pointer; }
    }
    .address-textfield-container{ display: flex; flex-direction: column; width: 100%; color: #3D3935; font-weight: 600;
      input{ padding:10px; border: 1px solid #C6C6C6; border-radius: 6px;
      }
      .single-textfield{ display: flex; flex-direction: column; gap: 8px;width: 100%; 
        .address{ margin-bottom: 20px; }
      }
      .double-textfield{ display: flex; gap: 20px; width: 100%; padding-bottom: 20px;}
    }

    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; }
      .close-btn { color: #1a66ff; background: #fff; border: 2px solid #1a66ff; }
    }
    .search-tag-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 10px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; margin: 20px auto; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .tags-menu {
      & { width: 100%; min-height: 50px; max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { display: flex; flex-direction: column; align-items: center; height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 52px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
          p { display: flex; margin: 0; font-size: 14px; }
        }
        .add-new-tag {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .tag-text { color: #1a66ff; font-weight: 600; }
        }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-wrap: wrap; }
      .author-tag {
        & { height: 24px; background: #e3e8f0; padding: 10px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;  }
        .remove-author { height: 16px; width: 16px; cursor: pointer; }
      }
    }
  }
}
.add-orders-container {
  & { width: 100%; max-width: 650px; padding: 20px; max-height: 80vh; overflow-y: scroll; }
  .search-tag-container {
    & { position: relative; height: 40px; width: 100%; padding: 0 10px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; margin: 20px auto; }
    input { 
      & { width: calc(100% - 40px); border: none; font-size: 18px; }
      &:focus { outline: none; }
    }
    .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
  }
  .tags-menu {
    & { width: 100%; min-height: 50px; max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
    ul {
      & { display: flex; flex-direction: column; align-items: center; height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
      li { 
        & { height: 52px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; align-items: center; justify-content: flex-start; cursor: pointer; }
        &:hover { background: #eee; }
        p { display: flex; margin: 0; font-size: 14px; }
      }
      .add-new-tag {
        & { border: 1px solid #aaa; margin-top: 5px; }
        &:hover { background: #d4e3ff; }
        .tag-text { color: #1a66ff; font-weight: 600; }
      }
    }
  }
  .order-type { & { display: flex; justify-content: space-between; align-items: center; width: 100%; }
  .image-box { & { display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 10px; cursor: pointer; } 
    img { border: 4px solid #C6C6C6; border-radius: 10px; padding: 15px; }
    h3 { color: #C6C6C6; }
    .active-img { border-color: #AA7B59; }
    .active-h3 { color: #AA7B59; }
  }
}
.order-source { & { margin: 20px 0; width: 100%;}
  .soures-group { & { display: flex; justify-content: space-between; align-items: center; margin-top: 10px; width: 100%; flex-wrap: wrap;  }
    .image-box { & { display: flex; justify-content: space-between; align-items: center; flex-direction: column; gap: 2px; cursor: pointer; } 
      img { border: 2px solid #C6C6C6; border-radius: 10px; padding: 10px; }
      h4 { color: #C6C6C6; width: 80px; overflow: hidden; white-space: nowrap; text-align: center; }
      .active-img { border-color: #AA7B59; }
      .active-h4 { color: #AA7B59; }
    }
  }
 }
 .buttons-group { & { display: flex; justify-content: space-between; align-items: center; gap: 10px; margin-top: 20px; } 
    button { width: 100%; flex-shrink: 1; }
  }
}

@media screen and (max-width: 950px) {
  .add-orders-container {
    .order-type { 
      .image-box { 
        img { width: 130px; height: 130px; }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .add-orders-container {
    .order-type { 
      .image-box { 
        img { width: 100px; height: 100px; padding: 5px; }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .add-orders-container {
    .order-type { & { flex-direction: column; justify-content: center; align-items: center; margin-top: 20px; gap: 10px; }
      .image-box { 
        img { width: 130px; height: 130px;}
      }
    }
    .buttons-group { & { display: flex; justify-content: center; align-items: center; flex-direction: column-reverse; } } 
  }
}

.create-promotion-modal-container{
    & { width: auto; min-width: 550px; padding: 10px; max-height: 80vh; overflow-y: scroll; }
    .input-box{ & { margin: 20px 0; } 
      input, textarea { width: 100%; padding: 10px; border: 2px solid #C6C6C6; border-radius: 5px; font-size: 14px; line-height: normal; margin-top: 5px;}
      .MuiInputBase-root{width: 100%; font-size: 14px;}
    }
    .buttons-group { & { display: flex; justify-content: space-between; align-items: center; gap: 10px; margin-top: 40px; } 
      button { width: 100%; flex-shrink: 1; padding: 10px; font-size: 14px; line-height: normal; margin-top: 5px; }
    }
}

.confirm-order-status-container {
  & { max-height: 600px; width: auto; min-width: 550px; padding: 20px; }
  header { display: flex; justify-content: space-between; align-items: center; }
  section {
    & { display: flex; flex-direction: column; height: 90%; }

    .confirmation-options {
      & { margin-left: 12px; }
      .MuiTypography-root { font-family: "Nunito", sans-serif; }
    }

    .aditional-options { padding: 10px; background-color: #FCFAF9; margin-bottom: 20px; }

    .address-card-container{ display: flex; flex-direction: column; gap: 10px;
      .card{ background-color: #FCFAF9; padding: 10px 0px; border-radius: 10px;  width: 100%;
        .address-card{
        width: 100%; display: flex; gap: 20px; align-items: center;
          p{ color: #3D3935; font-size: 16px; } 
          }
        .edit-icon{
          display: flex; width: 100%;align-items: center;justify-content: flex-end; gap: 5px; margin-bottom: 10px; margin-right: 10px;  }
      }
      .add-address { background-color: #EEEEEE; border-radius: 10px; display: flex; font-size: 18px; align-items: center; justify-content: center; color: #9B9B9B; height: 43px;padding: 10px; margin-bottom: 10px; cursor: pointer; }
    }
    
    input { padding:10px; border: 1px solid #C6C6C6; border-radius: 6px; }
    .single-textfield {
      & { display: flex; flex-direction: column; gap: 8px;width: 100%; }
      .address { margin-bottom: 20px; }
    }
    .double-textfield{ display: flex; gap: 20px; width: 100%; padding-bottom: 20px;}

    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; }
      .close-btn { color: #1a66ff; background: #fff; border: 2px solid #1a66ff; }
    }

    .search-tag-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 10px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .tags-menu {
      & { width: 100%; min-height: 50px; max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 40px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
        }
        .add-new-tag {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .tag-text { color: #1a66ff; font-weight: 600; }
        }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-wrap: wrap; }
      .author-tag {
        & { height: 24px; background: #e3e8f0; padding: 10px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;  }
        .remove-author { height: 16px; width: 16px; cursor: pointer; }
      }
    }

  }
}

.delete-order-modal-container{
  & { width: 300px; height: auto; }
  .btns{ 
    & { padding-top: 24px; display: flex; align-items: center; margin: auto; justify-content: space-around; }
    .close-btn{ border: 1px solid #AA7B59; color: #AA7B59; }
    .delete-btn{ background-color: #AA7B59; color: white; }
  }
}

.add-featured-blog-container { 
  & { display: flex; flex-direction: column; gap: 8px; overflow-y: scroll; } 
  .blog-header { 
    & { display: flex; justify-content: space-between; }
    .close-btn { cursor: pointer; }
  }
  .featured-blogs { 
    & { display: flex; justify-content: space-between; background-color: #f1f1f1; padding: 8px 4px; border-radius: 4px; align-items: center; gap: 12px; }
    .featured-blog-title { 
      & { width: 84%; }
    }
    .add-btn { 
      & { height: 32px; width: 15%; background-color: #fff; border:2px solid #AA7B59; color: #AA7B59; border-radius: 4px; cursor: pointer; display: flex; align-items: center; justify-content: center; }
    }
  } 
}

.publish-modal { 
  & { display: flex; flex-direction: column;width: 100%; }
  .heading {
    & { display: flex; align-items: center; justify-content: center; gap: 1rem; }
  }
  .close-modal { 
    & { display: flex; gap: 1rem;justify-content: space-between; }
    p {
      & { font-size: 20px; font-weight: 500; width: 80%; }
    }
  }
    .footer {
      & { display: flex; align-items: center; justify-content: space-between; width: 100%; gap: 1rem; margin-top: 2rem; }
      .cancel-btn { 
        & { width: 50%; background-color: #fff; color: #AA7B59; border: 2px solid #AA7B59; }
      }
      .confirm-btn { 
        & { width: 50%; background-color: #AA7B59; color: #fff; }
      }
    }
}
.modal { 
  & { display: flex; flex-direction: column;width: 100%; }
  .close-modal { 
    & { display: flex; gap: 1rem;justify-content: space-between; }
    p {
      & { font-size: 20px; font-weight: 500; width: 80%; }
    }
  }
    .footer {
      & { display: flex; align-items: center; justify-content: space-between; width: 100%; gap: 1rem; margin-top: 2rem; }
      .cancel-btn { 
        & { width: 50%; background-color: #fff; color: #AA7B59; border: 2px solid #AA7B59; }
      }
      .confirm-btn { 
        & { width: 50%; background-color: #AA7B59; color: #fff; }
      }
    }
}