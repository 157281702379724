.promotion-page {
  & { height: 100%; }
  
  .template-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; position: relative; }
    .heading-flex{& {display: flex; gap: 10px;}
      h1{cursor: pointer;}
    }
    .filters-box{ & { position: absolute; width: 100%; max-width: 300px; background-color: white; top: 92%; z-index: 999; right: 1rem; box-shadow: 0px 2px 10px #0000001A; border-radius: 5px; padding: 10px; }
      .single-filter { & {display: flex; justify-content: space-between; align-items: center; gap: 20px; font-size: 14px; line-height: 18px; font-weight: 600; margin: 10px 0;}
        input[type='checkbox'] { accent-color: #aa7b59; }
      }
      button { margin-top: 20px; width: 100%;}
    }
  }
  .template-section {
    & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }
  }
  .header-actions {
    & { display: flex; align-items: center; justify-content: center; gap: 10px; }
    .btn-create{min-width: auto; white-space: nowrap;}
  }
  .template-section {
    & { height: calc(100% - 60px); overflow-y: scroll; }
    .MuiDataGrid-root { background-color: transparent; color: #9B9B9B; }
    .MuiDataGrid-columnHeaderTitle { color: #9B9B9B; font-size: 14px; text-transform: capitalize !important; }
    .MuiDataGrid-root {
      & { border: none !important; }
      .MuiDataGrid-cellContent{white-space: wrap;}
      .MuiDataGrid-iconSeparator { visibility: hidden; }
      .MuiDataGrid-row { 
        & { box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 3px; margin: 5px 60px 5px 8px; border-radius: 10px; background-color: #fff; font-size: 14px !important; margin-right: 30px; color: #3D3935; width: calc(100% - 60px); }
        .MuiDataGrid-cell { 
          & { border-bottom: 0px !important; }
        }
      }
    }
    & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }
    .view-more-button { background-color: #AA7B59; padding: 7px 20px; font-size: 14px; color: #ffffff; font-weight: 700; width: fit-content; margin-left: auto; border-radius: 5px; cursor: pointer;}
  }
  .single-promotion {
    .tab-flex { &{display: flex; align-items: center; gap: 20px; height: 30px;}
      h3 { border-bottom: 2px solid transparent; cursor: pointer;}
      .active-tab { border-color: #AA7B59;}
    }
    .customers-data {
      .customers-table{height: calc(100% - 90px);}
      .MuiDataGrid-root { background-color: transparent; color: #9B9B9B; }
      .MuiDataGrid-columnHeaderTitle { color: #9B9B9B; font-size: 14px; text-transform: capitalize !important; }
      .MuiDataGrid-root {
        & { border: none !important; }
        .MuiDataGrid-iconSeparator { visibility: hidden; }
        .MuiDataGrid-row { 
          & { box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 3px; margin: 5px 60px 5px 8px; border-radius: 10px; background-color: #fff; font-size: 14px !important; margin-right: 30px; color: #3D3935; width: calc(100% - 60px); }
          .MuiDataGrid-cell { 
            & { border-bottom: 0px !important; }
          }
        }
      }
      .start-btn{background-color: #636951; padding: 10px 30px; border: none; border-radius: 5px; margin-left: auto; color: white; font-size: 20px; line-height: 26px; width: fit-content;}
      & { height: calc(100%); padding: 1rem; overflow-y: scroll; }
    }
    .promotion-setup { &{width: 100%; height: calc(100% - 90px); background-color: #fff; box-shadow: 0px 2px 10px #0000001C; margin-top:30px; border-radius: 10px; display: flex; flex-direction: column;}
      h2{padding: 1rem;}
      .promotion-tabs { & {display: flex; justify-content: flex-start; align-items: center; margin: 10px 0;}
        .tab{width: 100%; flex-shrink: 1; font-size: 16px; line-height: 20px; padding: 1rem; text-align: center; color: #9B9B9B; font-weight: 700; padding-inline: 0em calc(2em + .25em); position: relative; overflow: hidden;}
        .active-tab{ background:#F4EDE7}
        .triangle{width: 0; height: 100%; position: absolute; right: 0; top: 0; border-top: 25px solid transparent; border-bottom: 25px solid transparent; border-left: 30px solid #F4EDE7; background-color: white;}
        .completed-tab{background:#F4EDE7}
        .arrow-lower{ width: 3px; height: 200%; background-color: #fff; position: absolute; right: 0; top: 50%; transform: translateY(-49%) rotate(45deg);}
        .arrow-upper{ width: 3px; height: 200%; background-color: #fff; position: absolute; right: 0; top: 50%; transform: translateY(-51%) rotate(135deg);}
      }
      .select-customers{ &{width: 100%; height: 100%; display: flex; justify-content: center; align-items: flex-start; padding: 2rem; gap: 2rem; flex-shrink: 1;}
        .left, .right{width: 50%; height: 100%;}
        .left {
          .input-box{ &{width: 100%;}
            .label{margin-bottom: 5px;}
            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{border: 1px solid #C6C6C6; width: 100%;}
          }
        }
      }
      .choose-type{ &{width: 100%; height: 100%; display: flex; justify-content: start; align-items: stretch; margin: 2rem 0; padding: 0 2rem; gap: 2rem; flex-shrink: 1; overflow: hidden;}
        .left, .right{width: 50%; height: 100%;}
        .left {
          .input-box{ &{width: 100%;}
            .label{margin-bottom: 5px;}
            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{border: 1px solid #C6C6C6; width: 100%;}
          }
          .content{& {width: 100%; margin-top: 1rem;}
            .label{margin-bottom: 5px;}
            textarea{width: 100%; padding: 1rem;}
          }
        }
        .right{ &{display: flex; flex-direction: column;}
          .image{width: 100%; height: 100%; flex-shrink: 1;}
          img{height: calc(100% - 2rem); margin: auto;}
        }
      }
      .test-preview{ &{width: 100%; height: 100%; flex-shrink: 1; overflow: hidden; padding: 2rem;}
        .button-flex{display: flex; justify-content: space-between; align-items: center; width: 100%; margin-top: 20px;}
        img{height: 100%; max-height: 450px; margin: auto;}
      }
    }
    .button-flex{display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 1rem 2rem;}
  }
  .status { color: white; border-radius: 20px; padding: 5px; width: 90px; font-size: 12px; text-align: center; line-height: normal;}
  .bg-active { background-color: #636951;}
  .bg-inactive { background-color: #CD4A4A;}
  .bg-draft { background-color: #C6C6C6; }
  .btn-container { 
    & { display: flex; gap: 15px;}
  }
  }