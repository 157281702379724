.edit-recipe-container{
    input{ width: 100%; border: 2px solid #CACACA; font-weight: 500; flex: 1 1; border-radius: 5px; margin-right: auto; height: 40px; padding-left: 0.5rem; background: #ffffff; }
    .recipe-drawer-header{
        header{
            &{ display: flex; align-items: center; gap: 15px; }
        }
    }
    .recipe-input-box-container{
        &{ padding: 1rem; }
        .ingredient-input-box-container{
            &{ margin: 10px 0; }
            .form-label{
                &{ text-transform: capitalize; margin-bottom: 0.3rem; font-weight: 600; }
            }
        }
        .ingredient-save-changes-btn{
            &{ margin: 30px 0; display: flex; align-items: center; gap: 20px; width: 100%; }
            button{     
                &{ width: 100%; padding: 5px; }
            }
            .cancel-btn{
                &{ font-weight: bold; background: transparent; border: 2px solid #AA7B59; color: #AA7B59; border-radius: 4px; }
            }
            .save-btn{
                &{ border: 2px solid #AA7B59; font-weight: bold; background: #AA7B59; color: white; border-radius: 4px; }
            }
        }
    }
}