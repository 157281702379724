.orders-printing-page {
  & { width: 100%; display: flex; align-items: center; justify-content: center; gap: 5px; flex-wrap: wrap; max-width: 715px; }
  .order {
    & { position: relative; min-height: 519px; width: 354px; border: 1px solid black; padding: 10px; padding-bottom: 1rem; }

    .order-no-date {
      & { display: flex; align-items: flex-start; justify-content: space-between; flex-direction: column; font-size: 10px; width: 100px; }
    }

    .address-section {
      & { display: flex; align-items: center; justify-content: space-between; font-size: 10px; }
    }

    .order-items-table {
      & {  font-size: 10px; margin-top: 1rem; }
      .table-container {
        & { position: relative; }
        .product-quantity {
          & { display: flex; align-items: center; justify-content: center; border: 1px solid #000; min-width: 17px; min-height: 17px; font-size: 9px; position: absolute; top: -6px; right: -8px; border-radius: 100%; background: #ffffff;  color: #000000; }
        }
        table {
            & { width: 100%; border: 1px solid rgb(0, 0, 0); margin-bottom: 8px; padding-right: 10px; }
        }
        th {
          & { border-bottom: 1px solid black; }
        }
        
        td {
          & { text-align: center; }
        }
      }
    }

    .total {
      & { position: absolute; bottom: 1px; right: 10px; font-size: 10px;  font-weight: 600; }
    }

  }
}