.orders-page {
  & { height: 100%; }

  .template-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
    .header-btns {
      & { display: flex; align-items: center; justify-content: center; gap: 1rem; }
    }
  }
  .template-section {
    & { height: calc(100% - 60px); overflow-y: scroll; }
    .MuiDataGrid-root { background-color: transparent; color: #9B9B9B; }

    .MuiDataGrid-columnHeaderTitle { color: #9B9B9B; font-size: 14px; text-transform: capitalize !important; }

    .MuiDataGrid-root {
      
      & { border: none !important; }
      .MuiDataGrid-iconSeparator { visibility: hidden; }

      .MuiDataGrid-row { 
        & { box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 3px; margin: 5px 60px 5px 8px; border-radius: 10px; background-color: #fff; font-size: 14px !important; margin-right: 30px; color: #3D3935; width: calc(100% - 60px); }
        .MuiDataGrid-cell { 
          & { border-bottom: 0px !important; }
          .action-icon { cursor: pointer; transition: transform .4s ease-in-out; }
          .action-icon:hover { transform: scale(1.2); }
          .delete-btn{ cursor: pointer; transition: transform .4s ease-in-out; padding-left: 20px;}
          .delete-btn:hover{ transform: scale(1.2); }}
        .status-chip { height: 24px; border-radius: 7px; padding: 6px; font-size: 12px; text-transform: uppercase; font-weight: 700; display: flex; align-items: center; color: #fff; justify-content: center; }
        .draft { background-color: #d7d7d7; }
        .confirmed { background-color: #636951; }
        .packing { background-color: #CD4A4A; }
        .dispatched { background-color: #dea156; }
        .delivered { background-color: #66b2f4; }
        .canceled { background-color: #4a0000; }
      }
    }
  }
}