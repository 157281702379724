@import url("https://use.typekit.net/esw5kys.css");
@import url("https://fonts.googleapis.com/css2?family=Rosario:ital,wght@0,300;0,600;0,700;1,400&display=swap");

$ivyFont: "Ivypresto-headline", serif;
$transatFont: "transat-text", sans-serif;


.products-page {
  & { height: 100%; }

  .template-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
  }
  .template-section {
    & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }

    .products-list {
      & { width: 100%; display: flex; align-items: center; align-content: flex-start; justify-content: flex-start; flex-wrap: wrap; gap: 16px 24px; }
      .product {
        & { width: 305px; height: 455px; border-radius: 8px; box-shadow: 0px 3px 10px #00000021; padding: 16px; }
        .product-img {
          & { position: relative; width: 100%; height: 304px; border-radius: 4px; background: #dddddd; }
          &>img {
            & { height: 100%; width: 100%; object-fit: cover; border-radius: 4px; }
          }
          .hover-div { display: none; }
          &:hover {
            .hover-div {
              & { z-index: 10; position: absolute; top: 0; left: 0; height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; background: #00000042; }

            }
          }
        }

        .product-title-container {
          & { margin-top: 1rem; display: flex; align-items: center; justify-content: space-between;  }
          .product-title {
            & { font-weight: 700; width: 75%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
          }
          .product-quantity {
            & { font-weight: 700; text-transform: capitalize; font-size: 14px; padding: 3px 6px; border-radius: 5px; border: 2px solid #ddd; }
          }
        }

        .product-variant {
          & { margin: 5px 0; }
        }

        .product-btns {
          & { display: flex; align-items: center; justify-content: space-between; }
          .production-btn { width: 134px; height: 32px; border-radius: 24px; background-color: #CD4A4A; color: #FFFFFF; }
          .edit-btns {
            & { gap: 10px; }
            .edit-btn {
              & { padding: 5px; cursor: pointer; }
            }
          }
        }
    
      }
    }
  }
}