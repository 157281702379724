.navbar {
  & { display: none; height: 60px; width: 100%; background: #e6e6e6; border-bottom: 1px solid #eee; }
}

.main-container {
  & { display: flex; align-items: center; justify-content: center; height: 100%; width: 100%; }

  //sidebar
  .sidebar {
    & { width: 200px; height: 100%; background: #FCFAF9; transition: 250ms; }

    .logo { 
      & { display: flex; align-items: center; justify-content: center; margin-top: 40px; }
      & img { max-width: 70%; width: 132px; height: 88px; }
    }

    nav { margin: 20px 0; }

    nav a {display: flex;align-items: center; width: 100%;margin: 0 auto;color: #3D3935;text-decoration: none;font-size: 1em;font-weight: 400;padding: 10px 5px;background: none; gap: 16px; margin: 0 12%; }
    nav .MuiSvgIcon-root { margin-top: -2px; margin-right: 10px; }

    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #111; font-weight: 600;}

    .menulogo {height: 32px;margin: 6px 0 2px -20px;}
    .marketing-active{ background-color: #F4EDE7;}
    .content-active { background-color: #F4EDE7; }
  
  }

  //main content area
  .main {
    & { width: calc(100% - 200px); height: 100%; }
  }
}