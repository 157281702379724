.recipe-ingredients-main-container{
    &{ padding: 20px; height: 100%; }
    .recipe-ingredient-heading-container{
        &{ height: 40px; display: flex; align-items: center; justify-content:space-between; }
        .recipe-ingredient-heading{
            &{ font-size: 24px; }
        }
        button{
            &{ display: flex; gap: 10px; align-items: center; color: white; background: #AA7B59; border: none; outline: none; font-size: 16px; padding: 5px 10px; border-radius: 3px; font-weight: 600; }
        }
    }

    .recipe-ingredients-table{
        &{ width: 100%; max-height: calc(100% - 40px); overflow-y: scroll; }
        table { height: 100%; }
        tr{
            th{
                &{ text-align: left; font-size:14px; color:#9B9B9B; font-weight: normal; padding: 20px 10px; }
            }
        }
        .ingredient-row{ 
            &{ box-shadow: 0px 2px 10px #0000000D; border-radius: 10px; margin: 20px 0;}
        }
        td{
            &{ padding: 20px 10px; font-size: 14px; width: 5%; }
            h4{
                &{ font-size: 14px; }
            }
            svg{
                &{  cursor: pointer; font-size: 24px; }
            }
        }
        .ingredient-data{
            &{ display: flex; justify-content: space-between; width: 100%; }
        }
        tbody{
          & { overflow-y: scroll; }
            tr{
                &{ box-shadow: 0px 2px 10px #0000000D;}
            }
        }
        .drag-icon{
            svg{
                &{ color: #121212; }
            }
        }
        .recipe-edit-ip-btn{
            &{ display: flex; align-items: center; justify-content: space-between; gap: 10px;}
            input{ border: 2px solid #9b9b9b; border-radius: 5px; width: 100%; width: 371px; padding: 7px 10px; }
            button{ width: 61px; background-color: #AA7B59; color: white; padding: 10px; border: none; border-radius: 5px; }
        }
        .recipe-ingredient-edit-icon{
            &{ display: flex; align-items: center; }
            svg{ color: #272727 !important; } 
        }
    }
}