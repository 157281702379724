@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;
.MuiDrawer-paper{background-color: #FCFAF9 !important;}
.container {
  & { background-color: #FCFAF9;  }
}

.drawer {
  
  & { background-color: #FCFAF9; width: 85vw; height: 100%; }
  .tool-button { height: 24px; width: 24px; margin-right: 10px; cursor: pointer; color: #007C89; }
  .active { border: 1px solid blue; }
  .disable { border: none; }
  header {
    & { padding: 20px 50px; color: #000000; width: auto; max-width: 100%; height: 100px;  display: flex; align-items: center; gap: 30px; }
    h1 {
      & { margin: 0; font-size: 24px; color: #000000; align-items: center; display: flex; }
      .back-button { height: 24px; width: 24px; margin-right: 10px; }
    }
  }

  .upload-image-container { 
    & {width: 150px; height: 150px; cursor: pointer; }
    img { border-radius: 5px; width: 100%; height: 100%; }
   }

  .MuiTabs-flexContainer { & {padding: 0 50px;} 
    .MuiButtonBase-root { color: #3D3935; font-weight: 700; padding: 10px 20px; min-height: 0; font-size: 18px; text-transform: capitalize;}
    .Mui-selected { background-color: #DCD3CB; border-radius: 5px; }
  }
  .MuiTabs-indicator{display: none !important;}
  .tab-panel { &{ padding: 20px; }
    .light-bg { &{background-color: #ffffff; padding: 20px 40px; border-radius: 10px; width: 100%; max-width: 1000px; margin: auto; box-shadow: 0 0 10px 0 #00000010;}
      // .Target-root{ height: 500px !important;}
    }
    .MuiTypography-root { font-weight: 700;}
    .flex { & {display: flex; justify-content: center; align-items: center; margin-top: 20px; gap: 40px;}
      .card{&{background-color: #ffffff; width: 210px; height: 140px; display: flex; justify-content: center; align-items: center; flex-direction: column; border-radius: 10px; box-shadow: 0 0 10px 0 #00000010;}
        .number{color: #AA7B59; font-size: 32px; font-weight: 700;}
        .text{color: #000000; font-size: 20px; font-weight: 700;}
      }  
    }
  }
  .user-details-card { &{ width: 90%; max-width: 400px; margin: 10px auto; background-color: white; padding: 20px; border-radius: 8px; box-shadow: 0 0 10px 0 #00000010; font-size: 16px; font-weight: 500;}
    .single-detail {display: flex; align-items: center; gap: 15px; margin: 10px 0;}
    .single-detail-editable {&{display: flex; justify-content: space-between; align-items: center; gap: 15px; margin: 10px 0;}
      .inner-flex {display: flex; align-items: center; gap: 15px;}
    }
  }

  .orders-panel { 
    &{ padding: 20px; }
    .search-flex { &{display: flex; justify-content: flex-end; align-items: center; gap: 15px; }
      input { background-color: transparent; border: 2px solid #9B9B9B; border-radius: 5px; padding: 5px 10px; font-weight: 500; font-size: 12px; width: 100%; flex-shrink: 1; max-width: 300px;}
      button { background-color: #AA7B59; color: white; border: none; border-radius: 5px; padding: 5px 10px; font-weight: 500; font-size: 12px;}
    }
    .template-section {
      .MuiDataGrid-root { background-color: transparent; color: #9B9B9B; }
      .MuiDataGrid-columnHeaderTitle { color: #9B9B9B; font-size: 14px; text-transform: capitalize !important; }
      .MuiDataGrid-root {
        & { border: none !important; }
        .MuiDataGrid-iconSeparator { visibility: hidden; }
        .MuiDataGrid-row { 
          & { box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 3px; margin: 5px auto; border-radius: 10px; background-color: #fff; font-size: 14px !important; color: #3D3935; }
          .MuiDataGrid-cell { 
            & { border-bottom: 0px !important; }
          }
        }
      }
      .status { color: white; border-radius: 20px; padding: 3px; width: 90px; font-size: 12px; text-align: center;}
      .bg-delivered { background-color: #636951;}
      .bg-canceled { background-color: #CD4A4A;}
      .bg-on-the-way { background-color: #E6CAB3; }
      & { height: calc(60vh - 60px); padding: 1rem; overflow-y: scroll; overflow-x: hidden; }
    }
  }

  .address-panel{
      &{ padding: 20px; }
      .search-flex { &{display: flex; justify-content: flex-end; align-items: center; gap: 15px; }
        input { background-color: transparent; border: 2px solid #9B9B9B; border-radius: 5px; padding: 5px 10px; font-weight: 500; font-size: 12px; width: 100%; flex-shrink: 1; max-width: 300px;}
        button { background-color: #AA7B59; color: white; border: none; border-radius: 5px; padding: 5px 10px; font-weight: 500; font-size: 12px;}
      }
    .address-flex{ & {display: flex; justify-content: flex-start; align-items: stretch; gap: 21px; margin: 20px auto; flex-wrap: wrap;}
      .address-card { & {padding: 20px; border-radius: 8px; box-shadow: 0 0 10px 0 #00000010; font-size: 16px; background-color: white; width: calc(100% / 3 - 14px); min-height: 150px;}
        .buttons { display: flex; justify-content: flex-end; align-items: center; gap: 15px; margin-top: 10px; }
      }
      .add-address-card { & {padding: 20px; border-radius: 8px; box-shadow: 0 0 10px 0 #00000010; font-size: 16px; background-color: #EEEEEE; width: calc(100% / 3 - 14px); display: flex; justify-content: center; align-items: center; color: #9B9B9B; font-size: 24px; font-weight: 500; gap: 10px; min-height: 150px;}
      }
    }
  }

  section { 
    
    & { overflow-y: scroll; height: calc(100% - 60px); width: 100%; padding-top: 1rem; }

    .edit-blog-post { margin-top: 1rem; }

    .editor-div { margin: 0 auto; margin-bottom: 40px; width: 80vw; max-width: 1076px; background-color: #FFFFFF; padding: 40px; display: flex;
      flex-wrap: wrap;
      border-radius: 8px;

      span{
        font-size: 12px;
        color:#9A9A9A;
      }

      .title-header{
        width: 65%;
        padding-right: 40px;
        .author-row{
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 16px;
          .author-image{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            object-fit: cover;
            background-color: #000000;
          }
          
          .author-tag {
            & {  position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
              padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
            .remove-author { height: 16px; width: 16px; cursor: pointer; }
            .add-author { height: 18px; width: 18px; cursor: pointer; }
            
          }
        }

        .edit-blog-post {
          & { font-size: 14px; font-weight: 600; border: 1px solid #ccc; border-bottom: none; padding: .5rem .5rem; background: #e3e8f0; }
        }
        .quill { margin-bottom: 1rem; }
        .blog-snippet-quill .ql-toolbar.ql-snow + .ql-container.ql-snow {
          height: 150px;
        }
        .blog-snippet-quill .ql-toolbar.ql-snow {
          display: none;
        }

      }
      .content-container{
        display: flex;
        gap:40px;

        .text-editor-container{
          width: 65%;
          
          
          .image-container{
            margin-bottom: 24px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .post-image{
              width: 100%;
              max-width: 100%;
              max-height: 360px;
              border-radius: 10px;
              margin: 0;
              padding: 0;

              
            }

            .upload-image-buttons{
              position: absolute;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .upload-button{

                background-color: #1359E4;
                color: white;
              }

              .remove-button{

                background-color: transparent;
                border: 2px solid #1359E4;
                .buttons{
                  color:#1359E4;
                }
              }

              .buttons{
                color:white;
              }
            
            }
          }
          
          
        }
        .myeditor{
          
          .ql-snow{ border: none !important; 
          .ql-editor{
            padding: 0 !important; margin: 0 !important;
          }
        }
          
        }
      }

      .second-column{
        .trending-text{
          font-size: 16px;
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
          color:#9B9B9B;
        }
        .trending-items{
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;
          gap:16px;
          p{
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 14px;
            color: #9B9B9B;
          } 
          .trending-image{
             height: 104px;
             border-radius: 10px;
          }
        }  
      }   
    }
  }

  .blog-edit-section { 
    & { position: relative; width: 100%; overflow-y: scroll;  overflow-x: hidden; padding: 5px 0; background-color: #FFFFFF; }

    .blog-flower-1 {
      & { top: 300px; left: -260px; position: absolute; width: 624px; height: 629px; }
    }
    .blog-flower-2 {
      & { top: -200px; right: -200px; position: absolute; width: 624px; height: 629px; }
    }

    .edit-blog-post {
      margin-top: 1rem;
    }

    .editor-div{
      margin: 20px auto;
      width: 80vw;
      max-width: 1076px;
      background-color: #FFFFFF;
      padding: 40px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 8px;

      .blog-author {
        font-size: 14px;
        color:#5c5c5c;
      }
      .remove-blog-author {
        font-size: 14px;
        color:#5c5c5c;
        margin-top: 7px;
      }

      .title-header{
        width: 100%;
        padding-right: 40px;
        .author-row{
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 16px;
          .author-image{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            object-fit: cover;
            background-color: #000000;
          }
          
          .author-tag {
            & {  position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
              padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
            .remove-author { height: 16px; width: 16px; cursor: pointer; }
            .add-author { height: 18px; width: 18px; cursor: pointer; }
            
          }
        }

        .edit-blog-post {
          & { font-size: 14px; font-weight: 600; border: 1px solid #ccc; border-bottom: none; padding: .5rem .5rem; background: #e3e8f0; }
        }
        .quill { margin-bottom: 1rem; }
        .blog-snippet-quill .ql-toolbar.ql-snow + .ql-container.ql-snow {
          height: 150px;
        }
        .blog-snippet-quill .ql-toolbar.ql-snow {
          display: none;
        }

      }
      .content-container {
        & { margin-top: 2rem; display: flex; width: 100%; gap:40px; }
        

        .text-editor-container{
          & { width: 100%; }
          .image-container{
            margin-bottom: 24px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #aaa;
            border-radius: 5px;
            width: 689px;
            height: 455px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              margin: 0;
              padding: 0;
              object-fit: contain;
            }
            iframe {
              width: 580px;
              max-width: 580px;
              max-height: 360px;
              height: 300px;
            }

            .placeholder {
              & { height: 200px; width: 200px; }
            }

            .upload-image-buttons{
              position: absolute;
              display: flex;
              gap: 20px;
              inset: auto auto 10px 5px;

              .upload-button{
                height: 30px;
                background-color: #1359E4;
                color: white;
                font-size: 12px;
              }

              .remove-button{
                height: 30px;
                background-color: transparent;
                border: 2px solid #ffffff;
                color: #FFF;
                .buttons{
                  color:#ffffff;
                }
                font-size: 12px;
              }

              .buttons{
                color:white;
              }
            
            }
            .image-toggle-button {
              position: absolute; inset: auto 5px 5px auto; background: #ffffff87; padding: 0 1rem; border-radius: 5px; color: #000000;
            }
            .upload-video-input {
              position: absolute; width: 300px; height: 30px; inset: auto auto 10px 5px; background: #272727; border-radius: 5px; color: #FFF;
              input { background: #272727; border: none; color: #FFF; width: 100%; height: 30px; border-radius: 5px; padding: 0 5px; }
              input:focus { outline: none; }
            }
          }
          
          
        }
        .myeditor{
          & { min-height: 300px; width: 100%; }
          .ql-snow{
            & { min-height: 300px; border: none !important; width: 100%; } 
          .ql-editor{
            & { min-height: 300px; padding: 0 !important; margin: 0 !important; }
          }
        }
          
        }
        

      }

      .image-and-title-container {
        & { position: relative; width: 100%; height: 455px; }
        .image-container {
          & { position: absolute; top: 0; right: -100px; display: flex; align-items: center; justify-content: center; width: 689px; height: 455px; background: #000; }
          .img {
            & { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; position: relative; }
            &>img {
              & { height: 100%; width: 100%; }
            }
            .upload-image-buttons {
              & { display: none; align-items: center; justify-content: center; gap: 1rem; position: absolute; bottom: 10px; left: 10px; }
            }
            &:hover {
              .upload-image-buttons {display: flex;}
            }
          }
        }

        .title-and-author-container {
          & { position: absolute; top: 100px; left: -100px; display: flex; align-items: center; justify-content: center; padding: 10px 5px; width: 75%; min-height: 100px; background: #fcfaf9d4; }
          .title-and-author {
            & { position: relative; min-height: 100%; width: 100%; }
            .title-container {
              & { width: 100%; }
              .title-editor {
                & { resize: none; width: 100%; border: none; background: transparent; color: #636951; font-family: 'EB Garamond', serif; font-size: 48px; font-weight: 600; }
                &:focus { outline: none; }
              }
            }

            .blog-category {
              & { font-size: 14px; font-weight: 600; text-transform: uppercase; color: #B1B4A8; position: absolute; top: -20px; left: 0; display: flex; align-items: center; }
              hr { min-width: 60px; margin-right: 10px; }
            }

            .blog-author-and-date {
              & { font-size: 14px; font-weight: 600; text-transform: uppercase; color: #B1B4A8; position: absolute; bottom: -40px; left: 0; display: flex; align-items: center; gap: 8px; }
            }
          }
        }
      }

      .second-column{
        .trending-text{
          font-size: 16px;
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
          color:#9B9B9B;
        }
        .trending-items{
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;
          gap:16px;
          p{
            margin: 0;  padding: 0;  font-weight: bold;  font-size: 14px; color: #9B9B9B;
          }
          
          .trending-image{ height: 104px; border-radius: 10px;
          }

        }
        
      }
      
      
    }
   
  }



  .double-column-form { 
    & { display: flex; align-items: center; }
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

}

.order-drawer {

  .status-chip { height: 28px; border-radius: 7px; padding: 6px; font-size: 14px; text-transform: uppercase; font-weight: 700; display: flex; align-items: center; color: #fff; justify-content: center; margin-left: 20px; }
  .draft { background-color: #d7d7d7; }
  .packing { background-color: #CD4A4A; }
  .dispatched { background-color: #dea156; }
  .delivered { background-color: #636951; }
  .canceled { background-color: #4a0000; }

  .order-drawer-address {
    & { background-color: #FFFFFF; box-shadow: 0px 2px 10px #0000000F; border-radius: 8px; margin-bottom: 10px; padding: 10px; margin: 0 20px 20px 20px; }
    .order-drawer-header {
      & {display: flex; justify-content: space-between; border-bottom: 1px solid #E3E3E3; }
      .header-section-1 { display: flex; justify-content: space-around; margin-left: 15px; }
      
      .header-section-2 { display: flex; justify-content: space-around; align-items: center; padding-right: 10px; }
      .date-input { height: 32px; border-radius: 4px; border: 1px solid #C6C6C6; font-size: 16px; font-weight: 600; margin-right: 15px; width: 256px; padding-inline: 5px; }
      .save-date-button { background-color: #AA7B59; border-radius: 4px; height: 32px; font-size: 16px; font-weight: 600; color: #ffffff; text-transform: capitalize; }
    }
  }

  // Order Details Drawer 
  .order-details { 
    & { position: relative; padding: 15px 30px; font-size: 14px; overflow-y: scroll; }
    h1 { font-family: $mulish; font-size: 18px; }
    .address-section { 
      & { display: flex; justify-content: space-between; font-size: 14px; }
      .customer-info { }
      .shop-info { & { margin-right: 15px; }
        .shop-name { font-size: 16px; font-weight: 700; font-family: $mulish; }
        p {font-weight: 600; font-size: 16px; }
      }
    }
    
    .black-btn { font-family: $mulish; background-color: #313131; color: #fff; padding: 2px 10px; font-size: 13px; }
    .products-table-container { & { margin: 30px auto 0; }
      table {
        th { font-size: 16px; font-weight: 600; }
      }
    }
    .customer-info {
      .customer-name-section { display: flex; align-items: center; }
      .customer-name { font-size: 16px; font-family: $mulish; font-weight: 700; }
      .customer-address { margin-left: 65px; }
      .customer-address p { color: #3D3935; font-size: 16px; font-weight: 600; }
      .customer-contact-info {margin-left: 65px; }
      .customer-contact-info p { color: #3D3935; font-size: 16px; font-weight: 600; }
      // a { font-size: 13px; }
    }
    
    p { margin: 8px 0; }

    .order-quantity { 
      & { width: 100px; }
      .MuiOutlinedInput-input { text-align: right; }
    }
    .order-price {
      & { width: 100px; }
      .MuiOutlinedInput-input { text-align: right; }
    }
    .order-total {
      & { width: 100px; }
      .MuiOutlinedInput-input { text-align: right; }
    }
  }
  .MuiPaper-root {width: 95%; border-radius: 8px; box-shadow: 0px 2px 10px #0000000F; padding: 10px; margin: 20px; width: calc(100% - 40px); }
  .MuiTableCell-root { font-family: $mulish; font-weight: 600; font-size: 16px; }
  .MuiTableCell-head { font-family: $mulish; font-weight: 700; font-size: 16px; }
  .MuiOutlinedInput-root { font-family: $mulish; font-size: 14px; width: 80%; }
  .MuiOutlinedInput-input { padding: 8px; }
  .MuiSelect-select { padding: 8px; }
  .MuiMenuItem-root { font-family: $mulish; font-size: 14px; }
  .MuiButtonBase-root-MuiMenuItem-root { font-family: $mulish; font-size: 14px; }

  .totals {
    & {  width: calc(100% - 40px); background-color: #FFFFFF; border-radius: 8px; box-shadow: 0px 2px 10px #0000000F; padding: 10px; margin: 0px 20px; }
    .total-row { 
      & { display: flex; justify-content: space-between; font-size: 16px; font-weight: 500; }
      .spacer { flex: 1 1 auto; font-weight: 600; }
      .description { flex: 0 1 100px; text-align: right; padding: 10px 16px; font-weight: 600; }
      .amount { flex: 0 1 100px; text-align: right; padding: 10px 16px; font-weight: 600; }
    }
    .total {  font-weight: 900; }
  }

  .order-btns { 
    & { position: fixed; bottom: 0; background: #efefef; width: 85vw; display: flex; padding: 15px 30px; margin-left: 0; justify-content: space-between; }
    .spacer { flex: 1 1 auto; }
    .confirm-btn { background-color: #eb4d4b; color: #ffffff; font-weight: 700; padding: 5px 10px; margin-left: 20px; }
    .cancel-btn { background-color: #fff; color: #313131; font-family: $mulish; font-size: 13px; }
    .edit-products-btn {  margin: 0 30px 0 30px; background-color: #636951; color: #FFFFFF; border-radius: 4px; }
  }

}

.product-drawer {

  header { padding: 0 30px; padding-left: 30px; display: flex; justify-content: space-between; align-items: center; }
  .product-drawer-details {
    & { width: calc(100% - 40px);background-color: #FFFFFF; box-shadow: 0px 2px 10px #0000000F; border-radius: 8px; margin-bottom: 10px; padding: 20px; margin: 0 20px 20px 20px; }
    
    // .order-drawer-header {
    //   & {display: flex; justify-content: space-between; border-bottom: 1px solid #E3E3E3; }
    //   .header-section-1 { display: flex; justify-content: space-around; margin-left: 15px; }
      
    //   .header-section-2 { display: flex; justify-content: space-around; align-items: center; padding-right: 10px; }
    //   .date-input { height: 32px; border-radius: 4px; border: 1px solid #C6C6C6; font-size: 16px; font-weight: 600; margin-right: 15px; width: 256px; padding-inline: 5px; }
    //   .save-date-button { background-color: #AA7B59; border-radius: 4px; height: 32px; font-size: 16px; font-weight: 600; color: #ffffff; text-transform: capitalize; }
    // }
  }
}


//blog drawer container

.blog-drawer-container { 
  & { width: 100vw !important; height: 100vh; padding: 0; overflow: hidden; background: #F6F7FA; }
  .blog-drawer {
    & { width: 100%; height: 100%; display: grid; grid-template-rows: 160px 1fr 100px; }

    .blog-drawer-header {
      & { background: #E5E5E8; }
      header {
        & { border-bottom: none; height: 40px; }
      }
      .blog-webpage-clone {
        & { width: 100%; background: #FFF; height: 36px; margin-top: 3rem;  }
        .webpage-clone {
          & { position: relative; width: 100%; height: 100%; box-shadow: 0px -5px 10px 0px #aaaaaa40; }
          .webpage-link {
            & { position: relative; width: 100%; height: 100%;  display: flex; align-items: center; z-index: 10;  }
            .link-icon { margin: 0 5px; }
            .link-container {
              & { flex: 1; display: flex; align-items: center;background: #E5E5E8; height: 80%; margin: 0 1rem; border-radius: 20px; }
              span {
                & { margin-left: 1rem; font-size: 14px; font-weight: 700;  }
              }
              input { 
                & { flex: 1; font-size: 14px; border: none; background: transparent; padding-top: 3px; margin-right: 2rem; margin-left: -1px; font-weight: 700; font-family: "Nunito", sans-serif; }
                &:focus { outline: none; }
              }
            }
          }
          .title-tab {
            & { z-index: 9; position: absolute; display: flex; align-items: center; gap: 10px; height: 35px; width: 300px; padding: 0 10px; background: #ffffff; top: -33px; left: 16px; border-top-right-radius: 15px; border-top-left-radius: 15px; box-shadow: 0px -5px 10px 0px #aaaaaa40; }
            img { height: 20px; width: 20px; }
            span { flex: 1; white-space: pre; overflow: hidden; text-overflow: ellipsis; font-size: 14px; font-weight: 600; }

          }
          .action-buttons-div {
            & { position: absolute; top: -45px; align-items: center; right: 1rem;  display: flex; gap: 10px; }
              .save-button{ background-color: #1359E4; font-size: 16px !important; height: 30px; }
              .cancel-button{ background-color: transparent; height: 30px; color: #1359E4; border: 1px solid #1359E4; font-size: 16px !important; font-weight: 600; font-family: "Nunito";}
              .status-container  {
                & { display: flex; align-items: center; justify-content: center; gap: 10px; margin-right: 1rem; }
                .status-button { padding: 8px; border-radius: 50px !important; color: #9B9B9B; background-color: #E9EAEE; cursor: pointer; } 
                .published { background-color: #1ABC9C; color: #FFF; }
              }
          }
        }

        .meta-tags-container {
          & { position: absolute; width: 100%; background: #FFF; z-index: 100; padding: 0 2rem; box-shadow: 0px 15px 15px 0px #aaaaaa80; }
        }
      }
      .editor-toolbar-container {
        & { position: relative; box-shadow: 0px 5px 15px 0px #aaaaaa30; }
        .editor-toolbar-control {
          & { background: #ffffff; position: absolute; inset: auto auto -25px auto; height: 30px; width: 30px; display: flex; align-items: center; justify-content: center; cursor: pointer; box-shadow: 0px 5px 15px 0px #aaaaaa30; border-radius: 0 0 5px 5px; }
        }
        .tools-div { background: #ffffff; display: flex; height: 40px; flex-wrap: wrap; justify-content: center; align-items: center; border: 0; } 
          .tools-button { display: flex; flex: 1; align-items: center; justify-content: center; } .ql-formats { display: flex; } 
          .action-buttons-div { display: flex; gap: 10px; }
          .save-button { background-color: #1359E4; font-size: 16px !important; height: 30px; }
          .cancel-button { background-color: transparent; height: 30px; color: #1359E4; border: 1px solid #1359E4; font-size: 16px !important; font-weight: 600; font-family: "Nunito"; }
      }
  }

    .tags-container { margin: 0 auto; display: flex; padding: 1rem; background: #ffffff; display: flex; align-items: center; justify-content: space-between; gap: 10px; align-items: center; width: 100%; min-height: 100px; border: 0px; }
    .tags-container .topic-row { width: 50%; max-width: 50%; display: flex; align-items: flex-start; flex-wrap: wrap; gap: 5px; height: 100%;  padding: 0 5px 0 5px; } 
    .tags-container .topic-row .single-tag { position: relative; font-size: 14px; color: #9B9B9B; height: 24px; background-color: #E9EAEE; padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; } 
    .tags-container .topic-row .single-tag .remove-author { height: 16px; width: 16px; cursor: pointer; }
    .tags-container .topic-row .single-tag .add-author { height: 18px; width: 18px; cursor: pointer; } 
    .tags-container .topic-row .single-tag:has(.add-author) { background: #FFF; border: 1px solid #111; color: #111; cursor: pointer; }
    .tags-container .tags-row { width: 50%; max-width: 50%; display: flex; align-items: flex-start; flex-wrap: wrap; gap: 5px; height: 100%; border-left: 1px solid #AAA; padding: 0 5px 0 5px; } 
    .tags-container .tags-row .single-tag { position: relative; font-size: 14px; color: #9B9B9B; height: 24px; background-color: #E9EAEE; padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; } 
    .tags-container .tags-row .single-tag .remove-author { height: 16px; width: 16px; cursor: pointer; } 
    .tags-container .tags-row .single-tag .add-author { height: 18px; width: 18px; cursor: pointer; } 
    .tags-container .tags-row .single-tag:has(.add-author) { background: #FFF; border: 1px solid #111; color: #111; cursor: pointer; } 
    .tags-container .status-container { border-left: 1px solid #AAA; width: 13%; display: flex; align-items: center; justify-content: center; gap: 10px; height: 100px; } 
    .tags-container .status-container .status-button { padding: 7px 16px; border-radius: 50px !important; color: #9B9B9B; background-color: #E9EAEE; cursor: pointer; } 
    .tags-container .status-container .published { background-color: #1ABC9C; color: #FFF; }

  }
}


//create-order-drawer

.create-order-drawer{ background-color: red; }


// reatilers details drawer

.retail-drawer {
  .head { font-size: 24px; color: #3D3935; font-weight: bold; margin: 20px 0; display: flex; align-items: center; gap: 20px; }

  .details-section { width: 100%; height: 100%; } 
  .details-section .buttons-div { display: flex; gap: 20px; margin: 20px 0; }
  .details-section .buttons-div button { border: none; border-radius: 5px; padding: 8px 26px; font-size: 20px; color: #3D3935; } 
  .details-section .statistics .graph { } 
  .details-section .statistics .graph-details { display: flex; justify-content: center; align-items: center; gap: 24px; } 
  .details-section .statistics .graph-details .card { background-color: white; align-items: center; display: flex; flex-direction: column; height: auto; width: 208px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; border-radius: 10px; padding: 8px; margin: 8px; } 
  .details-section .statistics .graph-details .card .title { color: #AA7B59; font-size: 34px; font-weight: bold; margin: none !important; padding: none !important; }
  .details-section .statistics .graph-details p { color: #3D3935; font-size: 20px; margin: none; }
  .details-section .header-location { display: flex; justify-content: end; gap: 20px; }
  .details-section .details .card { display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 1rem; }
  .details-section .details .card .row { font-weight: bold; display: flex; width: 100%; justify-content: space-between; align-items: center; }
  .details-section .details .card .row div { display: flex; align-items: center; gap: 8px; } 
  .details-section .details .card .row-end { display: flex; align-items: center; justify-content: end; width: 100%; }
  .details-section .images { display: flex; margin: 1rem; flex-wrap: wrap; gap: 10px; } 
  .details-section .images img { height: 242px; width: 233px; } 
  .details-section .location .card-div { width: 100%; display: flex; align-items: center; flex-wrap: wrap; height: 100%; } 
  .details-section .location .card-div .card { min-width: 310px; min-height: 142px; padding: 20px; margin: 10px; font-size: 16px; color: #3D3935; } 
  .details-section .location .card-div .card-add { background-color: #EEEEEE; font-size: 24px; color: #9B9B9B; display: flex; align-items: center; justify-content: center; min-width: 310px; min-height: 142px; padding: 20px; margin: 10px; box-shadow: none !important; border-radius: 8px; }


  & { width: 80vw; padding-left: 20px; }
  header { width: calc(100% - 60px); padding: 0 30px; background: #efefef; padding-left: 30px; display: flex; justify-content: space-between; align-items: center; }

  .MuiTabs-flexContainer { background: #efefef; }

  section { background: #efefef; padding: 30px; min-height: calc(100vh - 130px); width: calc(100% - 60px); overflow-y: scroll; }

  .double-column-form { 
    & { display: flex; align-items: center; }
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

}


// product details drawer

.product-drawer {
  .head { font-size: 24px; color: #3D3935; font-weight: bold; margin: 20px 0; display: flex; align-items: center; gap: 20px; } 
  .head h1 { color: #3D3935; font-size: 24px; }

  .details-section{ & { width: 100%; height: 100%; }

  .buttons-div { display: flex; gap: 20px; margin: 20px 0; } 
  .buttons-div button { border: none; border-radius: 5px; padding: 8px 26px; font-size: 20px; color: #3D3935; }


    .statistics{
      .graph{ }
      h1 {margin: 5px auto; text-align: center; padding-top: 20px;}

      .graph-details{ & { display: flex; justify-content: center; align-items: center; gap: 24px; }
      .card { background-color: white; align-items: center; display: flex; flex-direction: column; height: auto; width: 208px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; border-radius: 10px; padding: 8px; margin: 8px; }
      .card .title { color: #AA7B59; font-size: 34px; font-weight: bold; margin: none !important; padding: none !important; }
      .card p { color: #3D3935; font-size: 20px; margin: none; }
      .top-bar { fill: red;}
      }
    }
    
    .details .card { display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 1rem; } 
    .details .card .row { font-weight: bold; display: flex; width: 100%; justify-content: space-between; align-items: center; } 
    .details .card .row div { display: flex; align-items: center; gap: 8px; }
    .details .card .row-end { display: flex; align-items: center; justify-content: end; width: 100%; }


    //inventory
    .margin {
      & { display: flex; text-align: center; justify-content: center; border: none; margin: 30px auto 0px; }
      .inventory-card { & { box-shadow: #0000000A 0px 2px 10px; height: 188px; width: 428px; border-radius: 10px; } 
      h1 { color: #3D3935; font-size: 48px; margin-bottom: 5px; } 
      .inventory-status { background-color: #636951; border-radius: 24px; color: #FFFFFF; font-size: 14px; line-height: 18px; } }    
    }

    .details { & { display: flex; flex-direction: column; align-items: center; }
    .details .recipe-heading { color: #3D3935; font-size: 16px; line-height: 20px; align-items: flex-start; width: 502px; margin: 10px; padding-left: 5px; } 
    .details .inventory-menu { display: flex; } 
    .details .recipe-text { border: 1px solid #C6C6C6; border-radius: 2px; width: 502px; height: 198px; overflow: scroll; }
    .details .MuiInput-underline::after { border-bottom: none; } 
    .details .MuiInput-underline::before { border-bottom: none; } 
    .details .MuiInputLabel-animated { padding-left: 10px; font-size: 18px; color: #9B9B9B; }
    .details .MuiInputLabel-shrink { color: #9B9B9B; padding-top: 10px; }
    .details .MuiInput-input { padding: 10px; font-family: inherit; } 
    .details .recipe-button { border-radius: 4px; background-color: #AA7B59; color: #FFFFFF; width: 502px; height: 40px; margin-top: 30px; margin-bottom: 10px; } }

    .product-details { & { margin: 10px auto 5px; padding-top: 20px; box-shadow: #0000000A 0px 2px 10px; border-radius: 10px; } 
    .product-details .details-card { width: 550px; height: 454px; padding: 5px 15px; } 
    .product-details .product-title { padding-top: 20px; font-size: 20px; color: #3D3935; } 
    .product-details .product-description { color: #3C3935; font-weight: 700; } }


    .images-container { & { margin: 10px auto 5px; padding-top: 20px; box-shadow: none; border-radius: 10px; } 
    .images-container .product-image-card { border-radius: 4px; width: 400px; height: 400px; border: none; margin: 0 auto 20px; } 
    .images-container .product-image { height: 400px; width: 400px; }
    .images-container .product-image-gallery { margin: 40px auto 10px; box-shadow: none; } 
    .images-container .gallery { width: max-content; box-shadow: none; } }


    .reviews { & { width: 654px; height: 622px; box-shadow: 0px 2px 10px #0000000A; background-color: #FFFFFF; margin: 30px auto 10px; } 
    .reviews .review { margin-top: 5px; margin-bottom: 25px; } 
    .reviews .reviews-heading { color: #3D3935; font-size: 20px; padding: 15px 15px; } 
    .reviews .review-container { font-family: inherit; padding-left: 25px; } 
    .reviews .reviewer-data { display: flex; justify-content: space-between; } 
    .reviews .review-name-ratings { display: flex; flex-direction: column; margin-left: 10px; }
    .reviews .reviewer-column { display: flex; } 
    .reviews .MuiRating-root { font-size: 18px; } 
    .reviews .rating { display: flex; }
    .reviews .rating-score { font-size: 14px; padding-left: 5px; }
    .reviews .name { color: #3D3935; font-size: 14px; line-height: 18px; }
    .reviews .rating-score { font-size: 14px; color: #3D3935; }
    .reviews .review-date { font-size: 14px; color: #989898; line-height: 18px; padding-right: 40px; align-self: end; padding-bottom: 12px; }
    .reviews .review-text { color: #3D3935; font-size: 16px; line-height: 20px; margin-left: 50px; margin-top: 5px; } }
  }

  
  

  .MuiTabs-flexContainer { background: #efefef; }

  .double-column-form { 
    & { display: flex; align-items: center; }
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

}

//product image-drwer
.product-image-drawer {
  & {position: relative; box-sizing: border-box; padding: 0.5rem 0 0 0.5rem;}
  & {max-height: 100vh;}
  .header {font-size: 24px; font-weight: bold; padding: 5px 10px; border-radius: 3px; background: #fefefe; border: 1px solid #777; width: fit-content; margin-bottom: 20px;}
  .card-container{
    .card {
      & {width: 350px; margin-right: 40px;}
      .img {
        &{ max-height: 400px; width: fit-content; margin-bottom: 25px;}
        img {height: 400px; width: 320px; object-fit: cover; border: 1px solid #777; border-radius: 3px;}
      }
    }
  }
  .card:first-of-type {
    & {width: 400px; margin-right: 40px;}
    .img {
      &{ max-height: 500px; width: fit-content; margin-bottom: 25px;}
      img {height: 500px; width: 400px; object-fit: cover; border: 1px solid #777; border-radius: 3px;}
    }
  }
  .MuiButton-root {background: #fefefe;}
  .btn {margin: 0 10px;}
  .center {text-align: center; align-items: center;}
  .space-between {justify-content: space-between;}
  .space-evenly {justify-content: space-evenly;}
  .justify-center {justify-content: center;}
  .flex {display: flex;}
}


.product-image-drawer-new {
  & { color: #3D3935; }

  .product-display-img {
    & { width: 100%; padding: 1rem; margin-bottom: 1rem; }
    .product-display-header { 
      & { font-size: 20px; font-weight: 700; margin-bottom: 10px; }
    }
    .product-display-content {
      & { display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 1rem; }
      .display-img {
        & { display: flex; align-items: center; justify-content: center; height: 500px; width: 350px; background: #e3e3e3; }
        &>img {
          & { height: 100%; width: 100%; object-fit: cover; }
        }
      }
    }
  }

  .product-additional-img {
    & { width: 100%; padding: 1rem; margin-bottom: 1rem; }
    .product-additional-header { 
      & { font-size: 20px; font-weight: 700; margin-bottom: 10px; }
    }
    .product-additional-content {
      & { width: 100%; }
      .additional-images {
        & { width: 100%; display: flex; align-items: center; align-content: flex-start; justify-content: flex-start; flex-wrap: wrap; gap: 1.5rem; }
        .additional-img {
          & { position: relative; width: 227px; height: 304px; }
          &>img {
            & { height: 100%; width: 100%; }
          }
          .edit-icon {
            & { display: none; cursor: pointer; align-items: center; justify-content: center; padding: 5px; background: #ffffff; border-radius: 4px; position: absolute; right: 10px; top: 10px; }
          }
          .delete-icon {
            & { display: none; cursor: pointer; align-items: center; justify-content: center; padding: 5px; background: #FFF; border-radius: 4px; position: absolute; right: 55px; top: 10px; }
          }

          &:hover {
            .edit-icon {
              & { display: flex; }
            }
            .delete-icon {
              & { display: flex; }
            }
          }
        }
        .add-additional-img {
          & { cursor: pointer; border-radius: 5px; display: flex; align-items: center; justify-content: center; flex-direction: column; width: 227px; height: 304px; background: #e3e3e3; }
          .add-icon { height: 70px; width: 70px; }
        }
      }
    }
  }
}

//Order Address Modal



.account-details {&{ max-height: 600px; width: auto; min-width: 550px; }
  .detail {display: flex; flex-direction: column; margin-top: 15px; }
  .detail label { font-size: 16px; font-weight: 700; margin: 10px 5px; }
  .detail input { border: 1px solid #C6C6C6; border-radius: 4px; height: 40px; align-items: center; }
  .detail input::placeholder {padding-left: 5px; }
  .save-changes-btn {& { margin-top: 25px; display: flex; justify-content: space-between; }
    .MuiButton-root { height: 40px; width: 239px; }

}
}