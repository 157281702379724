.featured-page-container {
    & { height: 95%; width: 100%; padding: 1rem; }
    header {
    & { height: 10%; width: 100%; }
    }
    .featured-page {
    & { height: 97.5%; width: 100%; overflow-x: scroll; padding-right: 5px; }
    .featured-entities-container {
        & {  }
        .featured-entities {
        & { display: flex; align-items: center; justify-content: center; gap: 1rem; flex-wrap: wrap; }
        .featured-entity {
          & { padding: 1rem; width: 550px; min-height: 540px; display: flex; align-items: center; justify-content: center; flex-direction: column; border: 3px dashed #aaa; border-radius: 10px; }
            .add-to-container {
              & {
                cursor: pointer; height: 400px; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 1rem; }
              &>span { font-weight: 600; color: #9B9B9B; font-size: 20px;}
            }
            .image {
              & { margin-bottom: 1rem; width: 100%; max-width: 300px; height: 400px; margin: 0 auto; background: #bcbcbc; border-radius: 8px; }
              img { height: 100%; width: 100%; object-fit: cover; border-radius: 8px; }
            }
            .title {
              & { margin-top: 10px; width: 100%; display: flex; align-items: flex-start; gap: 1rem; }
              .title-tag-wrapper {
                & { width: 90%; }
                .tag-input {
                  & { width: 100%; }
                  input {
                    & { width: 100%; height: 44px; }
                  }
                  textarea {
                    & { resize: none; }
                  }
                }
              }
              .title-text {
                & { width: 85%; margin: 0; }
              }
              .tag-input {
                & { margin-top: 10px; }
                &>span {
                  & { font-weight: 600; }
                }
                &>input {
                  & { width: 200px; border: 1px solid #9B9B9B; height: 30px; padding: 0 10px; border-radius: 5px; }
                  &:focus { outline: none; }
                }
                .include-title-input {
                  & { margin-left: 2rem; }
                }
                .submit-tag-btn {
                  & { width: 70px; height: 30px; color: #1a66ff; border: 2px solid #1a66ff; margin-left: 1rem; }
                }
              }
              .remove-btn {
                & { width: 15%; color: #eb4d4b; border: 2px solid #eb4d4b; border-radius: 4px; }
              }
            }
          }
        }
      }
    }
  }
  