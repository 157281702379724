.automation-page {
    & { height: 100%; }
  
    .template-header {
      & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
    }
    .template-section {
      & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }
    }
    .header-actions {
      & { display: flex; align-items: center; justify-content: center; gap: 10px; }
    }
    .template-section {
      & { height: calc(100% - 60px); overflow-y: scroll; }
      .MuiDataGrid-root { background-color: transparent; color: #9B9B9B; }
      .MuiDataGrid-columnHeaderTitle { color: #9B9B9B; font-size: 14px; text-transform: capitalize !important; }
      .MuiDataGrid-root {
        & { border: none !important; }
        .MuiDataGrid-cellContent{white-space: wrap;}
        .MuiDataGrid-iconSeparator { visibility: hidden; }
        .MuiDataGrid-row { 
          & { box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 3px; margin: 5px 60px 5px 8px; border-radius: 10px; background-color: #fff; font-size: 14px !important; margin-right: 30px; color: #3D3935; width: calc(100% - 60px); }
          .MuiDataGrid-cell { 
            & { border-bottom: 0px !important; }
          }
        }
      }
      & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }
      .view-more-button { background-color: #AA7B59; padding: 7px 20px; font-size: 14px; color: #ffffff; font-weight: 700; width: fit-content; margin-left: auto; border-radius: 5px; cursor: pointer;}
    }
    .status { color: white; border-radius: 20px; padding: 5px; width: 90px; font-size: 12px; text-align: center; line-height: normal;}
      .bg-active { background-color: #636951;}
      .bg-inactive { background-color: #CD4A4A;}
      .bg-draft { background-color: #C6C6C6; }
  }