.authors-page { 
    & { height: 100%; background-color: #f4f4f4; }
    .template-header {
        & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
      }
    .template-body { 
			.authors { 
				& { display: flex;  gap: 20px; row-gap: 60px; margin: 20px; }
				.author { 
					& { width: 350px; height: 250px; border-radius: 5px; background-color: #fff; position: relative; display: flex; flex-direction: column; justify-content: space-around; }
					.profile-img { 
						& { position: absolute; z-index: 10; width: 100px; height: 100px; background-color: #d1d1d1; left: 35%; top: -10%; border-radius: 50%; box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); } 
						img { margin: auto; width: 100%; height: 100%; border-radius: 50%; }
					}
					
					.details { display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; margin-top: 100px; }
					.button { 
						& { display: flex; justify-content: space-around; margin: auto; width: 100px; }
						.btn { cursor: pointer; }
						
					}
				 }
			} 
		}
 }