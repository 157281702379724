.edit-topics-container{
  input{ width: 100%; border: 2px solid #CACACA; font-weight: 500; flex: 1 1; border-radius: 5px; margin-right: auto; height: 54px; padding-left: 0.5rem; background: #ffffff; }
    .topics-drawer-header{
      header{
          &{ display: flex; align-items: center; gap: 15px; }
      }
    }
    .topics-input-box-container{
      &{ padding: 0 1rem; }
        .sub-topics-input-box-container{
          &{ margin: 10px 0; }
            .form-label{
              &{ text-transform: capitalize; margin-bottom: 0.3rem; font-weight: 600; }
            }
        }
        .ingredient-save-changes-btn{
          &{ margin: 30px 0; display: flex; align-items: center; gap: 20px; width: 100%; }
            button {     
              & { width: 100%; padding: 5px; }
            }
            .cancel-btn{
                & { font-weight: bold; background: transparent; border: 2px solid #AA7B59; color: #AA7B59; border-radius: 4px; }
            }
        }
        .btn-container {
            & { display: flex; width: 100%; justify-content: flex-end; padding: 1rem 0;  }
            .save-btn{
                &{ border: 2px solid #AA7B59; font-weight: bold; background: #AA7B59; color: white; border-radius: 4px; height: 42px; text-transform: uppercase; }
            }
        }
    }
}