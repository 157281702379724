@import url("https://use.typekit.net/esw5kys.css");
@import url("https://fonts.googleapis.com/css2?family=Rosario:ital,wght@0,300;0,600;0,700;1,400&display=swap");

$ivyFont: "Ivypresto-headline", serif;
$transatFont: "transat-text", sans-serif;

.recipes-page {
  
	& { height: 100%; }

	.template-header { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
	.checkbox { cursor: pointer; }

	.template-section {
    & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }

		.recipe-table {
			& { width: 100%; }
			tr {
				th { font-size:14px; color:#9B9B9B; font-weight: normal; padding: 20px 0; }
			}
			.ingredient-row { box-shadow: 0px 2px 10px #0000000D; border-radius: 10px; margin: 20px 0}
			td {
				& { padding: 16px;  background: white; text-align: center; }
				a { text-decoration: none; }
				.recipe-img { margin: auto; width: 140px; }
				button {
					& { width: 115px; margin: auto; font-weight: bold; font-size: 14px; border-radius: 4px; gap: 10px; background: none; border: none; display: flex; align-items: center; }
					svg { cursor: pointer; color: #af8363; font-size: 24px; }
				}
				
        .ingredient-btn {
					& { cursor: pointer; background: #E9EAEE; color: #9B9B9B; padding: 5px 10px; position: relative; }
					.ingredients-count { position: absolute; right: -10px; top: -10px; background-color: #1359e4; padding: 4px; height: 22px; width: 22px; border-radius: 50px; color: #fff; font-size: 10px; font-weight: normal; }
					svg { font-size: 16px; }
				}
				.method-btn {
					& { cursor: pointer; background: #E9EAEE; color: #9B9B9B; padding: 5px 10px; position: relative; }
					.ingredients-count { position: absolute; right: -10px; top: -10px; background-color: #1359e4; padding: 4px; height: 22px; width: 22px; border-radius: 50px; color: #fff; font-size: 10px; font-weight: normal; }
					svg { font-size: 16px; }
				}
			}
      .recipe-image-container {
        & { width: 140px; }
      }
      .recipe-name {
        & {  }
        h5 { text-align-last: left; font-size: 16px; font-weight: 600; }
        p { text-align: justify; }
      }
      .action-btns {
        & { width: 115px; margin: auto; font-weight: bold; font-size: 14px; border-radius: 4px; background: none; border: none;  }
        svg { cursor: pointer; color: #af8363; font-size: 24px; }
      }
			.space-row { height: 20px; }
    }
	}
}